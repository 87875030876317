<template>
    <div>
        <vue-title title="Карьера | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div id="career" class="container py-4">
            <div class="text-center mb-3">
                <h1 class="mb-4 page-title">Карьера</h1>
            </div>
            <div class="row mb-5">
                <div class="col-12 col-lg-4 pr-3 pr-lg-0">
                    <div class="bg-white h-100">
                        <div class="pt-4 mx-4 pb-5">
                            <strong class="text-primary">КЕЛ</strong> – это надежная работа и уверенность в будущем. Вместе мы смотрим в сторону общего успеха.<br>
                            Если ты талантлив, стремишься к развитию и росту, стань частью нашей команды.
                        </div>
                        <div class="media pt-5 pt-lg-5 mb-2 ml-4">
                            <img src="@/assets/images/contacts/user.png" class="align-self-center mr-3" alt="РК, г. Алматы, ул. Майлина 85/2" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-1 mb-1">Галина Божко<br><small class="text-muted">Директор по персоналу</small></h3>
                            </div>
                        </div>
                        <div class="media pt-3 mb-2 ml-4">
                            <img src="@/assets/images/contacts/phone.png" class="align-self-center mr-3" alt="+7 (727) 3-122-110" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a href="tel:+77717016336" style="color: #212529;">+7 771 701 63 36</a></h3>
                            </div>
                        </div>
                        <div class="media pt-3 mb-3 ml-4">
                            <img src="@/assets/images/contacts/email.png" class="align-self-center mr-3" alt="info@kel.kz" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a href="mailto:galina.bozhko@kel.kz" style="color: #212529;">galina.bozhko@kel.kz</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8 pl-3 pl-lg-0">
                    <div class="bg-white h-100 d-flex align-items-center">
                        <img src="@/assets/images/career-company/1.jpeg" class="w-100">
                    </div>
                </div>
            </div>
            <div id="vacancies" class="text-center mb-3">
                <h1 class="mb-4 page-title">Вакансии</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!jobs">
                        <DxLoadIndicator
                                id="medium-indicator"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="jobs && jobs.length > 0">
                        <div class="card shadow-sm mb-5" v-for="(job, index) in jobs" :key="index">
                            <div class="card-header bg-white font-26 border-bottom-0">
                                <div class="row">
                                    <div class="col-12 col-md-6 text-secondary">
                                        {{ job.position }}
                                    </div>
                                    <div class="col-12 col-md-6 text-left text-md-right">
                                        <span class="card-text text-muted font-18">{{ job.rate }}</span>
                                    </div>
                                </div>
                                <p class="card-text font-16 mt-2" :id="'card-'+index+'-responsibilities'">
                                    <span class="mr-1" v-for="(responsibility, index) in job.responsibilities" :key="index">{{ responsibility }};</span>
                                </p>
                            </div>
                            <div :id="'card-'+index" class="card-body d-none pt-0">
                                <h3 class="card-title mb-2 mt-2">Обязаности</h3>
                                <p class="card-text mb-0" v-for="(responsibility, index) in job.responsibilities" :key="'res-'+index" v-html="'- '+responsibility"></p>
                                <h3 class="card-title mb-2 mt-2">Требования</h3>
                                <p class="card-text mb-0" v-for="(requirement, index) in job.requirements" :key="'req-'+index" v-html="'- '+requirement"></p>
                                <h3 class="card-title mb-2 mt-2">Условия</h3>
                                <p class="card-text mb-0" v-for="(condition, index) in job.conditions" :key="'c-'+index" v-html="'- '+condition"></p>
                                <hr>
                                <h3 class="card-title mb-2 mt-2">Галина Божко<br><small class="text-muted">Директор по персоналу</small></h3>
                                <p class="card-text mb-0">
                                    <span>Тел: <a href="tel:+77717016336">+7 771 701 63 36</a> </span>
                                </p>
                                <p class="card-text mb-0">
                                    <span>Email: <a href="mailto:galina.bozhko@kel.kz">galina.bozhko@kel.kz</a></span>
                                </p>
                            </div>
                            <div class="card-footer bg-white">
                                <div class="row">
                                    <div class="col-6">
                                        <button :id="'card-'+index+'-btn'" type="button" class="btn btn-primary btn-sm" @click="openDetail('card-'+index)">Подробно</button>
                                    </div>
                                    <div class="col-6 pt-1 font-14 text-right text-muted">
                                        <i class="far fa-calendar-alt mr-2"></i> {{ job.publisher_date }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="jobs && jobs.length == 0">
                        <h5 class="my-5">На данный момент открытых вакансий нет.</h5>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;

    import {Header, Footer} from '../../shared/components';
    import headerRoutes from './header-routes';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    //import json from '../../../../public/data/jobs'
    import axios from 'axios';

    export default {
        name: "CareerCompany",
        components: {
            Header,
            Footer,
            DxLoadIndicator
        },
        data() {
            return {
                headerRoutes,
                countJobs: 0,
                jobs: null
            }
        },
        mounted() {
            axios.get('/data/jobs.json?'+(Math.round(+new Date()/1000))).then((response) => {
                this.jobs = response.data;
            });
        },
        methods: {
            openDetail(id) {
                if($('#'+id).hasClass('d-none')) {
                    $('#'+id+'-responsibilities').addClass('d-none');
                    $('#'+id).removeClass('d-none');
                    $('#'+id+'-btn').html('Свернуть');
                } else {
                    $('#'+id+'-responsibilities').removeClass('d-none');
                    $('#'+id).addClass('d-none');
                    $('#'+id+'-btn').html('Подробно');
                }
            }
        }
    }
</script>

<style scoped>

</style>
