export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'CareerCompany',
            title: 'Карьера',
            hash: '#career'
        },
        {
            id: 2,
            name: 'CareerCompany',
            title: 'Вакансии',
            hash: '#vacancies'
        }
    ]
}


